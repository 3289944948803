<template>
  <div class="instruction-guide container">
    <van-tabs v-model="tabActive" color="#773DE1" sticky title-active-color="#773DE1" title-inactive-color="#999999"
              line-width="24px" swipeable animated>
      <van-tab :title="$t('mine.ios_title')">
        <div class="tc-tab" v-if="$i18n.locale == 'zh-tw'">
          <div class="tab" :class="{ 'tab-active': locale === 'zh-hk' }" @click="handeChange('zh-hk')">香港版本</div>
          <div class="tab" :class="{ 'tab-active': locale === 'zh-tw' }" @click="handeChange('zh-tw')">台灣版本</div>
        </div>
        <div class="images" v-if="$i18n.locale == 'zh-tw'">
          <a-row type="flex" v-show="locale === 'zh-hk'">
            <a-col :xs="24" :lg="8" v-for="(img, hk) in ios.hk" :key="hk">
              <img v-lazy="img" :key="img">
            </a-col>
          </a-row>
          <a-row type="flex" v-show="locale === 'zh-tw'">
            <a-col :xs="24" :lg="8" v-for="(img, tw) in ios.tw" :key="tw">
              <img v-lazy="img" :key="img">
            </a-col>
          </a-row>
        </div>
        <div class="images" v-else-if="$i18n.locale === 'zh-cn'">
          <a-row type="flex">
            <a-col :xs="24" :lg="8" v-for="(img, cn) in ios.cn" :key="cn">
              <img v-lazy="img" :key="img">
            </a-col>
          </a-row>
        </div>
        <div class="images" v-else>
          <a-row type="flex">
            <a-col :xs="24" :lg="8" v-for="(img, en) in ios.en" :key="en">
              <img v-lazy="img" :key="img">
            </a-col>
          </a-row>
        </div>
      </van-tab>

      <van-tab :title="$t('mine.android_title')">
        <div class="tc-tab" v-if="$i18n.locale == 'zh-tw'">
          <div class="tab" :class="{ 'tab-active': locale === 'zh-hk' }" @click="handeChange('zh-hk')">香港版本</div>
          <div class="tab" :class="{ 'tab-active': locale === 'zh-tw' }" @click="handeChange('zh-tw')">台灣版本</div>
        </div>
        <div class="images" v-if="$i18n.locale == 'zh-tw'">
          <a-row type="flex" v-show="locale === 'zh-hk'">
            <a-col :xs="24" :lg="8" v-for="(img, hk) in android.hk" :key="hk">
              <img v-lazy="img" :key="img">
            </a-col>
          </a-row>
          <a-row type="flex" v-show="locale === 'zh-tw'">
            <a-col :xs="24" :lg="8" v-for="(img, tw) in android.tw" :key="tw">
              <img v-lazy="img" :key="img">
            </a-col>
          </a-row>
        </div>
        <div class="images" v-else-if="$i18n.locale == 'zh-cn'">
          <a-row type="flex">
            <a-col :xs="24" :lg="8" v-for="(img, cn) in android.cn" :key="cn">
              <img v-lazy="img" :key="img">
            </a-col>
          </a-row>
        </div>
        <div class="images" v-else>
          <a-row type="flex">
            <a-col :xs="24" :lg="8" v-for="(img, en) in android.en" :key="en">
              <img v-lazy="img" :key="img">
            </a-col>
          </a-row>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabActive: 0,
      locale:'zh-hk',
      ios: {
        en: [
          "https://esim.qn.triproaming.cn/esimuse/app/en-ios/en_ios_1%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/en-ios/en_ios_2%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/en-ios/en_ios_3%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/en-ios/en_ios_4%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/en-ios/en_ios_5%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/en-ios/en_ios_6%402x.png",
        ],
        cn: [
          "https://esim.qn.triproaming.cn/esimuse/app/cn-ios/cn_ios_1%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/cn-ios/cn_ios_2%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/cn-ios/cn_ios_3%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/cn-ios/cn_ios_4%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/cn-ios/cn_ios_5%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/cn-ios/cn_ios_6%402x.png",
        ],
        hk: [
          "https://esim.qn.triproaming.cn/esimuse/app/hk-ios/hk_ios_1%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/hk-ios/hk_ios_2%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/hk-ios/hk_ios_3%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/hk-ios/hk_ios_4%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/hk-ios/hk_ios_5%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/hk-ios/hk_ios_6%402x.png",
        ],
        tw: [
          "https://esim.qn.triproaming.cn/esimuse/app/tw-ios/tw_ios_1%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/tw-ios/tw_ios_2%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/tw-ios/tw_ios_3%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/tw-ios/tw_ios_4%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/tw-ios/tw_ios_5%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/tw-ios/tw_ios_6%402x.png",
        ]
      },
      android: {
        en: [
          "https://esim.qn.triproaming.cn/esimuse/app/en-an/en_android_1%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/en-an/en_android_2%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/en-an/en_android_3%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/en-an/en_android_4%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/en-an/en_android_5%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/en-an/en_android_6%402x.png",
        ],
        cn: [
          "https://esim.qn.triproaming.cn/esimuse/app/cn-an/cn_android_1%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/cn-an/cn_android_2%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/cn-an/cn_android_3%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/cn-an/cn_android_4%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/cn-an/cn_android_5%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/cn-an/cn_android_6%402x.png",
        ],
        hk: [
          "https://esim.qn.triproaming.cn/esimuse/app/hk-an/hk_android_1%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/hk-an/hk_android_2%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/hk-an/hk_android_3%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/hk-an/hk_android_4%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/hk-an/hk_android_5%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/hk-an/hk_android_6%402x.png",
        ],
        tw: [
          "https://esim.qn.triproaming.cn/esimuse/app/tw-an/tw_android_1%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/tw-an/tw_android_2%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/tw-an/tw_android_3%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/tw-an/tw_android_4%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/tw-an/tw_android_5%402x.png",
          "https://esim.qn.triproaming.cn/esimuse/app/tw-an/tw_android_6%402x.png",
        ]
      },
    };
  },

  mounted() {
    const system = navigator.userAgent.toLowerCase()
    const { locale } = this.$route.query
    if (locale) this.locale = locale
    if (system.includes('ios') || system.includes('mac')) {
      this.tabActive = 0
    } else if (system.includes('android')) {
      this.tabActive = 1
    }
  },
  methods: {
    handeChange(locale){
      this.locale = locale
    }
  },
};
</script>
<style lang="scss" scoped>
.instruction-guide {
  font-size: 14px;
  .images {
    padding: 16px 0;
  }
  /deep/ .van-tab__text {
    font-weight: 600;
  }
  .tc-tab {
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .tab {
      cursor: pointer;
      width: 128px;
      height: 28px;
      border: 1px solid #747474;
      border-radius: 4px;
      color: #747474;
      line-height: 28px;
      text-align: center;
      transition: all 0.5s ease;
      &:first-child {
        margin-right: 40px;
      }
    }
    .tab-active {
      border: 1px solid #773DDF;
      color: #fff;
      background-color: #773DDF;
    }
  }
}
</style>
